var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[_c('v-col',{attrs:{"cols":12,"md":"6","offset-md":"3","lg":6,"offset-lg":3}},[_c('v-card',[_c('v-toolbar',{attrs:{"color":"primary darken-4","dark":""}},[_c('v-toolbar-title',[_vm._v("Minutes")])],1),(_vm.recent.length === 0 && _vm.older.length === 0)?_c('v-alert',{attrs:{"type":"info","outlined":""}},[_vm._v("There are currently no minutes available to view")]):_vm._e(),(_vm.recent.length > 0)?_c('v-list',_vm._l((_vm.recent),function(ref,fileIndex){
var name = ref.name;
var mimeType = ref.mimeType;
var path = ref.path;
var editName = ref.editName;
var selected = ref.selected;
return _c('v-list-item',{key:'recent-' + fileIndex,on:{"click":function($event){return _vm.open(name, path, fileIndex)}}},[(_vm.multiselect)?_c('v-list-item-avatar',[(path.substr(0, 7) !== 'http://' && path.substr(0, 8) !== 'https://')?_c('v-icon',[_vm._v(_vm._s(selected ? 'fas fa-check-square' : 'fal fa-square'))]):_vm._e()],1):_c('v-list-item-avatar',[(path.substr(0, 7) === 'http://' || path.substr(0, 8) === 'https://')?_c('v-icon',[_vm._v("fal fa-link")]):(mimeType === 'application/pdf')?_c('v-icon',[_vm._v("fal fa-file-pdf")]):(mimeType === 'application/zip' || mimeType === 'application/x-zip-compressed')?_c('v-icon',[_vm._v("fal fa-file-archive")]):(mimeType === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet')?_c('v-icon',[_vm._v("fal fa-file-spreadsheet")]):_vm._e()],1),_c('v-list-item-title',{staticStyle:{"white-space":"normal"}},[_vm._v(_vm._s(name))]),(_vm.multiselect)?_c('v-list-item-avatar',[(path.substr(0, 7) === 'http://' || path.substr(0, 8) === 'https://')?_c('v-icon',[_vm._v("fal fa-link")]):(mimeType === 'application/pdf')?_c('v-icon',[_vm._v("fal fa-file-pdf")]):(mimeType === 'application/zip' || mimeType === 'application/x-zip-compressed')?_c('v-icon',[_vm._v("fal fa-file-archive")]):(mimeType === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet')?_c('v-icon',[_vm._v("fal fa-file-spreadsheet")]):_vm._e()],1):_vm._e()],1)}),1):_vm._e(),(_vm.older.length > 0)?_c('v-expansion-panels',[_c('v-expansion-panel',[_c('v-expansion-panel-header',[_vm._v("Older Minutes")]),_c('v-expansion-panel-content',[_c('v-list',_vm._l((_vm.older),function(ref,fileIndex){
var name = ref.name;
var mimeType = ref.mimeType;
var path = ref.path;
var editName = ref.editName;
var selected = ref.selected;
return _c('v-list-item',{key:'older-' + fileIndex,on:{"click":function($event){return _vm.open(name, path, fileIndex)}}},[(_vm.multiselect)?_c('v-list-item-avatar',[(path.substr(0, 7) !== 'http://' && path.substr(0, 8) !== 'https://')?_c('v-icon',[_vm._v(_vm._s(selected ? 'fas fa-check-square' : 'fal fa-square'))]):_vm._e()],1):_c('v-list-item-avatar',[(path.substr(0, 7) === 'http://' || path.substr(0, 8) === 'https://')?_c('v-icon',[_vm._v("fal fa-link")]):(mimeType === 'application/pdf')?_c('v-icon',[_vm._v("fal fa-file-pdf")]):(mimeType === 'application/zip' || mimeType === 'application/x-zip-compressed')?_c('v-icon',[_vm._v("fal fa-file-archive")]):(mimeType === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet')?_c('v-icon',[_vm._v("fal fa-file-spreadsheet")]):_vm._e()],1),_c('v-list-item-title',{staticStyle:{"white-space":"normal"}},[_vm._v(_vm._s(name))]),(_vm.multiselect)?_c('v-list-item-avatar',[(path.substr(0, 7) === 'http://' || path.substr(0, 8) === 'https://')?_c('v-icon',[_vm._v("fal fa-link")]):(mimeType === 'application/pdf')?_c('v-icon',[_vm._v("fal fa-file-pdf")]):(mimeType === 'application/zip' || mimeType === 'application/x-zip-compressed')?_c('v-icon',[_vm._v("fal fa-file-archive")]):(mimeType === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet')?_c('v-icon',[_vm._v("fal fa-file-spreadsheet")]):_vm._e()],1):_vm._e()],1)}),1)],1)],1)],1):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }