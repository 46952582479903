<template>
  <v-row>
    <v-col :cols="12" md="6" offset-md="3" :lg="6" :offset-lg="3">
      <v-card>
        <v-toolbar color="primary darken-4" dark>
          <v-toolbar-title>Minutes</v-toolbar-title>
        </v-toolbar>
        <v-alert v-if="recent.length === 0 && older.length === 0" type="info" outlined>There are currently no minutes available to view</v-alert>
        <v-list v-if="recent.length > 0">
          <v-list-item v-for="({ name, mimeType, path, editName, selected }, fileIndex) in recent" :key="'recent-' + fileIndex" @click="open(name, path, fileIndex)">
            <v-list-item-avatar v-if="multiselect">
              <v-icon v-if="path.substr(0, 7) !== 'http://' && path.substr(0, 8) !== 'https://'">{{ selected ? 'fas fa-check-square' : 'fal fa-square' }}</v-icon>
            </v-list-item-avatar>
            <v-list-item-avatar v-else>
              <v-icon v-if="path.substr(0, 7) === 'http://' || path.substr(0, 8) === 'https://'">fal fa-link</v-icon>
              <v-icon v-else-if="mimeType === 'application/pdf'">fal fa-file-pdf</v-icon>
              <v-icon v-else-if="mimeType === 'application/zip' || mimeType === 'application/x-zip-compressed'">fal fa-file-archive</v-icon>
              <v-icon v-else-if="mimeType === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'">fal fa-file-spreadsheet</v-icon>
            </v-list-item-avatar>
            <v-list-item-title style="white-space: normal;">{{ name }}</v-list-item-title>
            <v-list-item-avatar v-if="multiselect">
              <v-icon v-if="path.substr(0, 7) === 'http://' || path.substr(0, 8) === 'https://'">fal fa-link</v-icon>
              <v-icon v-else-if="mimeType === 'application/pdf'">fal fa-file-pdf</v-icon>
              <v-icon v-else-if="mimeType === 'application/zip' || mimeType === 'application/x-zip-compressed'">fal fa-file-archive</v-icon>
              <v-icon v-else-if="mimeType === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'">fal fa-file-spreadsheet</v-icon>
            </v-list-item-avatar>
          </v-list-item>
        </v-list>
        <v-expansion-panels v-if="older.length > 0">
          <v-expansion-panel>
            <v-expansion-panel-header>Older Minutes</v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-list>
                <v-list-item v-for="({ name, mimeType, path, editName, selected }, fileIndex) in older" :key="'older-' + fileIndex" @click="open(name, path, fileIndex)">
                  <v-list-item-avatar v-if="multiselect">
                    <v-icon v-if="path.substr(0, 7) !== 'http://' && path.substr(0, 8) !== 'https://'">{{ selected ? 'fas fa-check-square' : 'fal fa-square' }}</v-icon>
                  </v-list-item-avatar>
                  <v-list-item-avatar v-else>
                    <v-icon v-if="path.substr(0, 7) === 'http://' || path.substr(0, 8) === 'https://'">fal fa-link</v-icon>
                    <v-icon v-else-if="mimeType === 'application/pdf'">fal fa-file-pdf</v-icon>
                    <v-icon v-else-if="mimeType === 'application/zip' || mimeType === 'application/x-zip-compressed'">fal fa-file-archive</v-icon>
                    <v-icon v-else-if="mimeType === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'">fal fa-file-spreadsheet</v-icon>
                  </v-list-item-avatar>
                  <v-list-item-title style="white-space: normal;">{{ name }}</v-list-item-title>
                  <v-list-item-avatar v-if="multiselect">
                    <v-icon v-if="path.substr(0, 7) === 'http://' || path.substr(0, 8) === 'https://'">fal fa-link</v-icon>
                    <v-icon v-else-if="mimeType === 'application/pdf'">fal fa-file-pdf</v-icon>
                    <v-icon v-else-if="mimeType === 'application/zip' || mimeType === 'application/x-zip-compressed'">fal fa-file-archive</v-icon>
                    <v-icon v-else-if="mimeType === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'">fal fa-file-spreadsheet</v-icon>
                  </v-list-item-avatar>
                </v-list-item>
              </v-list>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-card>
    </v-col>
  </v-row>
</template>
<script>
import { onMounted, ref, computed, watch } from '@vue/composition-api'
import axios from 'axios'

export default {
  props: {
    email: {
      type: String,
      default: ''
    },
    multiselect: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: 'C'
    }
  },
  setup (props, { root, emit }) {
    const accessToken = computed({
      get: () => root.$store.state.board.accessToken,
      set: (val) => root.$store.commit('board/setAccessToken', val)
    })
    const recent = ref([])
    const older = ref([])

    onMounted(() => { load() })
    async function load () {
      const query = { year: 'Minutes', type: props.type }
      if (accessToken.value != null && accessToken.value !== '') {
        query.accessToken = accessToken.value
        query.email = props.email
      }

      const { data } = await root.$feathers.service('board/files').find({ query })
      if (data.length > 0) {
        const { files } = data[0].col1[0]
        recent.value = [ ...files ].splice(0, 10)
        older.value = [ ...files ].splice(10)
      }
    }
    watch(() => props.type, () => {
      load()
    })

    function open (name, path, fileIndex) {
      if (props.multiselect) {
        emit('selectFile', fileIndex)
      } else if (path.substr(0, 4) === 'http') {
        window.open(path)
        if (root.$store.state.board.accessToken) {
          root.$feathers.service('board/users').patch(root.$store.state.board.accessToken, { lastAccess: new Date() })
        }
      } else {
        let token = window.localStorage['feathers-jwt']
        const basePath = process.env.VUE_APP_API_URL + process.env.VUE_APP_API_PATH.replace('/socket.io', '')
        axios({
          method: 'get',
          url: basePath + '/board/drive-file/' + path,
          responseType: 'blob',
          headers: { 'Content-Type': 'multipart/form-data', 'Authorization': `Bearer ${token}` }
        }).catch((e) => {
          let snackbar = { active: true, color: 'error', timeout: 6000, text: 'Error downloading file: ' + e }
          root.$store.dispatch('main/snackbar', snackbar)
        }).then((response) => {
          const blob = new Blob([response.data], { type: response.headers['content-type'] })
          const link = document.createElement('a')
          link.href = window.URL.createObjectURL(blob)
          link.download = name
          if (name.substr(-4, 1) !== '.' && name.substr(-5, 1) !== '.') {
            switch (response.headers['content-type']) {
              case 'application/pdf':
                link.download += '.pdf'
                break
              case 'txt':
                link.download += '.txt'
            }
          }
          link.click()
        })
      }
    }

    return {
      recent,
      older,
      open
    }
  }
}
</script>
